<template>
  <v-container class="px-5 mb-10">
    <v-row>
        <v-col cols="12">
          <div class="text-overline font-weight-bold">{{ showSavings }} Savings Balance</div>
          <div class="primary--text">
            <sup class="text-h6">&#8358;</sup>
            <span v-show="showSavings == 'Personal'" class="text-h3">{{ p | formatPrice}}</span>
            <span v-show="showSavings == 'Group'" class="text-h3">{{ g | formatPrice}}</span>
            <span v-show="showSavings == 'Agent'" class="text-h3">{{ a | formatPrice}}</span>
            <span v-show="showSavings == 'private savings'" class="text-h3">{{ g | formatPrice}}</span>
            <span v-show="showSavings == ''" class="text-h3">0.00</span></div>
        </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12">
        <v-tabs grow background-color="blue lighten-5" color="primary" class="rounded">
          <v-tabs-slider></v-tabs-slider>
          <v-tab @click="(showSavings = 'Personal'), getPersonalSavings(), (personalIsLoading = true), (createNew = '')">One Time Fixed Savings</v-tab>
          <!--v-tab @click="(showSavings = 'Group'), getPublicGroupSavings(), (publicgroupIsLoading = true), (createNew = '')">Group Savings</v-tab-->
          <!--v-tab>Rotational Savings</v-tab-->
          <!--v-tab @click="(showSavings = 'Agent'), getAgentSavings(), (agentIsLoading = true), (createNew = '')">Agent Savings</v-tab-->

          <v-tab-item class="py-4">
            <v-container fluid class="mb-5 text-center">
              <v-row>
                <v-col>
                  <v-card :class="showSavings == 'Personal' ? 'primary lighten-1 pa-2 rounded white--text' : 'pa-2 white--text secondary rounded'" elevation="0" @click="(showSavings = 'Personal'), (createNew = ''), getPersonalSavings(), (personalIsLoading = true)">
                    <div class="text-subtitle-1 font-weight-bold">My Savings</div>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card :class="showSavings == '' ? 'primary lighten-1 pa-2 rounded white--text' : 'pa-2 white--text secondary rounded'" elevation="0" @click="(createNew = 'Personal'), (showSavings = '')">
                    <div class="text-subtitle-1 font-weight-bold">Create Savings</div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <create-personal-savings v-if="createNew == 'Personal' && showSavings == ''" />
            <template v-if="personalIsLoading">
              <v-container class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg">
                <div class="py-15 text-center my-15">
                  <mini-loader class="text-center"></mini-loader>
                </div> 
              </v-container>
            </template>
            <template v-else>
              <div class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg" v-if="personalSavings != '' && showSavings == 'Personal'">
                <v-row>
                  <v-col cols="12" md="6" v-for="(index, i) in personalSavings" :key="i">
                    <router-link :to="{ name : 'SelectedSavings', params: { id : index.id }}">
                      <v-container>
                        <v-card @click="loadSpinner(), (selectandLoad = true)" class="pa-6 single-savings-card">
                          <v-row>
                            <v-col cols="12" class="mb-3">
                              <div class="p-4 text-h6 primary--text" flat> {{ index.name }} </div>
                              <p class="text-body-2 mt-2 secondary--text" v-if="index.description != null">{{ index.description }}</p>
                              <p class="text-body-2 mt-2 secondary--text mb-10" v-else></p>
                            </v-col>
                            <v-col>
                              <span class="text--disabled text-body-2">Balance</span> <br />
                              <span class="text-h6 primary--text font-weight-bold">&#8358;{{ index.balance | formatPrice }}</span>
                            </v-col>
                            <v-col class="text-right">
                              <span class="text--disabled text-body-2">Savings Target</span> <br />
                              <span class="text-h6 primary--text font-weight-bold">&#8358;{{ index.amount | formatPrice }}</span>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-container>
                    </router-link>
                  </v-col>
                </v-row>
              </div>
              <div class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg" v-if="personalSavings == '' && !createNew">
                <v-container class="py-15 text-center my-15">
                  <div class="text-subtitle-2 text--disabled">no personal savings created yet</div>
                </v-container>
              </div>
            </template>
          </v-tab-item>
          <v-tab-item class="py-4">
            <v-container fluid class="mb-5 text-center">
              <v-row>
                <v-col>
                  <v-card :class="showSavings == 'Group' ? 'primary lighten-1 pa-2 rounded white--text' : 'pa-2 white--text secondary rounded'" elevation="0" @click="(showSavings = 'Group'), getPublicGroupSavings(), (createNew = ''), (publicgroupIsLoading = true), (selectedSavings = [])">
                    <div class="text-subtitle-1 font-weight-bold">Explore</div>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card :class="showSavings == 'private savings' ? 'primary lighten-1 pa-2 rounded white--text' : 'pa-2 white--text secondary rounded'" elevation="0" @click="(showSavings = 'private savings'), getPrivateGroupSavings(), (createNew = ''), (privategroupIsLoading = true), (selectedSavings = [])">
                    <div class="text-subtitle-1 font-weight-bold">My Savings</div>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card :class="showSavings == '' ? 'primary lighten-1 pa-2 rounded white--text' : 'pa-2 white--text secondary rounded'" elevation="0" @click="(createNew = 'Group'), (showSavings = '')">
                    <div class="text-subtitle-1 font-weight-bold">Create Savings</div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <create-group-savings v-if="createNew == 'Group'" />
            <template v-if="showSavings == 'Group'">
              <v-container v-if="publicgroupIsLoading" class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg">
                <div class="py-15 text-center my-15">
                  <mini-loader class="text-center"></mini-loader>
                </div>
              </v-container>
              <div v-if="!publicgroupIsLoading">
                <div class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg" v-if="publicGroupSavings != ''">
                  <v-row>
                    <v-col cols="12" md="6" v-for="(index, i) in publicGroupSavings" :key="i">
                      <router-link :to="{ name : 'SelectedSavings', params: { id : index.id }}">
                        <v-container>
                          <v-card @click="(selectedSavings = index)" class="pa-6 single-savings-card">
                              <v-row>
                                <v-col class="pb-0">
                                  <div class="p-4 text-h6 primary--text" flat> {{ index.name | capitalize }} </div>
                                  <p class="text-body-2 mt-2 secondary--text" v-show="index.description != null">{{ index.description }}</p>
                                  <div v-show="index.description == null" class="pt-8"></div>
                                  <div class="mt-5 avatars">
                                      <!--span class="text-h6">&#8358;{{ index.balance | formatPrice }}</span-->
                                      <span class="avatar"><img alt="" width="25" :src="require('@/assets/icons/user.png')"/></span>
                                      <span class="avatar"><img alt="" width="25" :src="require('@/assets/icons/user.png')"/></span>
                                      <span class="avatar"><img alt="" width="25" :src="require('@/assets/icons/user.png')"/></span>
                                  </div>
                                </v-col>
                                <v-col cols="5" class="pb-0 mt-2 text-right">
                                  <template v-if="index.userId != user.id">
                                      <v-btn color="primary" @click.prevent="joinGroup(index.id)" outlined dark>Join</v-btn>
                                  </template>
                                  <template v-else>
                                      <div class="pt-8"></div>
                                  </template>
                                  <p class="mt-8">
                                      <span class="text--disabled text-body-2">Group Target</span> <br />
                                      <span class="text-h6">&#8358;{{ index.projected_saving | formatPrice }}</span>
                                  </p>
                                </v-col>
                              </v-row>
                          </v-card>
                        </v-container>
                      </router-link>
                    </v-col>
                  </v-row>
                </div>
                <div class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg" v-if="publicGroupSavings == '' && !createNew">
                  <v-container class="py-15 text-center my-15">
                    <div class="text-subtitle-2 text--disabled">All public group savings will be listed here</div>
                  </v-container>
                </div>
              </div>
            </template>
            <template v-if="showSavings == 'private savings'">
              <v-container v-if="privategroupIsLoading" class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg">
                <div class="py-15 text-center my-15">
                  <mini-loader class="text-center"></mini-loader>
                </div>
              </v-container>
              <div v-if="!privategroupIsLoading">
                <div class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg" v-if="privategroupSavings != '' && showSavings == 'private savings'">
                  <v-row>
                    <v-col cols="12" md="6" v-for="(index, i) in privategroupSavings" :key="i">
                      <router-link :to="{ name : 'SelectedSavings', params: { id : index.id }}">
                        <v-container>
                          <v-card @click="(selectedSavings = index)" class="pa-6 single-savings-card">
                            <v-row>
                              <v-col class="pb-0">
                                <div class="p-4 text-h6 primary--text" flat> {{ index.name | capitalize }} </div>
                                <p class="text-body-2 mt-2 secondary--text" v-show="index.description != null">{{ index.description }}</p>
                                <div v-show="index.description == null" class="pt-8"></div>
                                <div class="mt-5 avatars">
                                  <!--span class="text-h6">&#8358;{{ index.balance | formatPrice }}</span-->
                                  <span class="avatar"><img alt="" width="25" :src="require('@/assets/icons/user.png')"/></span>
                                  <span class="avatar"><img alt="" width="25" :src="require('@/assets/icons/user.png')"/></span>
                                  <span class="avatar"><img alt="" width="25" :src="require('@/assets/icons/user.png')"/></span>
                                </div>
                              </v-col>
                              <v-col cols="5" class="pb-0 mt-2 text-right">
                                <template v-if="index.userId != user.id">
                                  <v-btn color="primary" @click.prevent="joinGroup(index.id)" outlined dark>Join</v-btn>
                                </template>
                                <template v-else>
                                  <div class="pt-8"></div>
                                </template>
                                <p class="mt-8">
                                  <span class="text--disabled text-body-2">Group Target</span> <br />
                                  <span class="text-h6">&#8358;{{ index.projected_saving | formatPrice }}</span>
                                </p>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-container>
                      </router-link>
                    </v-col>
                  </v-row>
                </div>
                <div class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg" v-if="privategroupSavings == '' && !createNew">
                  <v-container class="py-15 text-center my-15">
                    <div class="text-subtitle-2 text--disabled">no private group savings created yet</div>
                  </v-container>
                </div>
              </div>
            </template>
          </v-tab-item>
          <v-tab-item class="py-4">
            <v-container fluid class="mb-5 text-center">
              <v-row>
                <v-col>
                  <v-card :class="showSavings == 'Agent' ? 'primary lighten-1 pa-2 rounded white--text' : 'pa-2 white--text secondary rounded'" elevation="0" @click="(showSavings = 'Agent'), getAgentSavings(), (createNew = '')">
                    <div class="text-subtitle-1 font-weight-bold">All Savings</div>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card :class="showSavings == '' ? 'primary lighten-1 pa-2 rounded white--text' : 'pa-2 white--text secondary rounded'" elevation="0" @click="(createNew = 'Agent'), (showSavings = '')">
                    <div class="text-subtitle-1 font-weight-bold">New Savings</div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <create-agent-savings v-if="createNew == 'Agent'"/>
            <template v-if="agentIsLoading">
              <v-container class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg">
                <div class="py-15 text-center my-15">
                  <mini-loader class="text-center"></mini-loader>
                </div>
              </v-container>
            </template>
            <template v-else>
              <div class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg" v-if="agentSavings != '' && showSavings == 'Agent'">
                <v-row>
                  <v-col cols="12" md="6" v-for="(index, i) in agentSavings" :key="i">
                    <router-link :to="{ name : 'SelectedSavings', params: { id : index.id }}">
                      <v-container>
                        <v-card @click="(selectandLoad = true)" class="pa-8 single-savings-card">
                          <v-row>
                            <v-col class="pb-0">
                              <div class="p-4 text-h6 primary--text" flat> {{ index.name | capitalize }} </div>
                              <p class="text-body-2 mt-2 secondary--text">{{ index.description | capitalize }}</p>
                              <div class="mt-5 avatars">
                                <span class="avatar"><img alt="" width="25" :src="require('@/assets/icons/user.png')"/></span>
                                <span class="avatar"><img alt="" width="25" :src="require('@/assets/icons/user.png')"/></span>
                                <span class="avatar"><img alt="" width="25" :src="require('@/assets/icons/user.png')"/></span>
                              </div>
                            </v-col>
                            <v-col cols="5" class="pb-0 mt-2 text-right">
                              <template v-if="index.userId != user.id">
                                <v-btn color="primary" @click.prevent="joinAgent(index.id)" outlined dark>Join</v-btn>
                              </template>
                              <template v-else>
                                <div class="pt-8"></div>
                              </template>
                              <p class="mt-8">
                                <span class="text--disabled text-body-2">Agent Target Savings</span> <br />
                                <span class="text-h6">&#8358;{{ index.amount | formatPrice }}</span>
                              </p>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-container>
                    </router-link>
                  </v-col>
                </v-row>
              </div>
              <div class="grey px-0 px-md-4 py-4 lighten-5 rounded-lg" v-if="agentSavings == '' && !createNew">
                <v-container class="py-15 text-center my-15">
                  <div class="text-subtitle-2 text--disabled">no agent savings created yet</div>
                </v-container>
              </div>
            </template>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CreatePersonalSavings from '@/components/savings/CreatePersonalSavings.vue'
import CreateGroupSavings from '@/components/savings/CreateGroupSavings'
import CreateAgentSavings from '@/components/savings/CreateAgentSavings'
import paystack from 'vue-paystack'
import MiniLoader from '@/components/MiniLoader.vue'

import { mapGetters } from 'vuex'
export default {
  components: {
    CreatePersonalSavings,
    CreateGroupSavings,
    paystack,
    CreateAgentSavings,
    MiniLoader
  },
  layout: 'dashboard',
  transition: 'default',
  data() {
    return {
      createNew: '',
      inviteDialog: false,
      inviteDialogAgent: false,
      loading: false,
      valid: true,
      rules: {
        required: value => !!value || 'This field is required'
      },
      menu: false,
      menu1: false,
      activeAccount: '',
      reference: '',
      paystackkey: process.env.VUE_APP_ENV_PAYSTACK_PUBLIC_KEY,
      selectedSavings: [],
      wallet: '',
      form: {},
      inviteAgent: {},
      personalSavings: '',
      publicGroupSavings: '',
      agentSavings: '',
      privategroupSavings: '',
      form: {},
      extendsavings: false,
      transfersavings: false,
      settings: false,
      quitsavings: false,
      groupmembers: false,
      agentgroupmembers: false,
      selectedGroupMembers: [],
      selectedSavingsHistory: [],
      joinrequests: false,
      agentjoinrequests: false,
      groupvote: false,
      selectedfortransfer: '',
      selectedforchange: '',
      selectedtoquit: '',
      selectedtoset: '',
      showSavings: 'Personal',
      savingsmembers: [],
      grouprequests: [],
      tovote: false,
      selectUser: false,
      selectedUser: [],
      commission: false,
      votemember: '',
      p: [],
      g: [],
      a: [],
      searchedMember: false,
      s: [],
      personalIsLoading: true,
      privategroupIsLoading: true,
      publicgroupIsLoading: true,
      agentIsLoading: true,
      selectandLoad: true,
      
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },

  filters: {
    capitalize: function (value) {
        if (!value) return ''
            value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },

  methods: {
    loadSpinner() {
      setTimeout(() => {
        this.selectandLoad = false
      }, 1000)
    },
    async getWalletBalance() {
      const data = {
        user_id: this.user.id,
      };
      try {
        let response = await this.$store.dispatch("auth/wallet", data, { root: true })
        if (response == 0) {
          this.wallet = "0";
        } else {
          this.wallet = response;
        }
      } catch (error) {
        
      }
    },
    async getLoanBalance() {
      const data = {
        user_id: this.user.id,
      };
      try {
        let response = await this.$store.dispatch("auth/loanbalance", data, { root: true })
        this.loan_balance = response;
      } catch (error) {
        
      }
    },
    async getPersonalSavings() {
      const data = {
        user_id: this.user.id,
      }
      try {
        let res = await this.$store.dispatch('savings/allpersonalsavings', data)
        this.personalSavings = res.accounts
        this.p = Object.values(this.personalSavings)
        this.p = this.p.map(({balance}) => ({balance}))
        this.p = this.p.map(({ balance }) => balance)
        this.p = this.p.reduce((a,b) => a + b, 0)
        setTimeout(() => {
          this.personalIsLoading = false
        }, 1000)
      } catch (err) {
        
      }
    },
    async getPrivateGroupSavings() {
      const data = {
        user_id: this.user.id,
      }
      try {
        let res =  await this.$store.dispatch('savings/allprivategroupsavings', data)
        this.privategroupSavings = res.groups
        this.g = Object.values(this.privategroupSavings)
        this.g = this.g.map(({balance}) => ({balance}))
        this.g = this.g.map(({ balance }) => balance)
        this.g = this.g.reduce((a,b) => a + b, 0)
        setTimeout(() => {
          this.privategroupIsLoading = false
        }, 1000)
      } catch (err) {
        
      }
    },
    async getPublicGroupSavings() {
      try {
        let res = await this.$store.dispatch('savings/allpublicgroupsavings')
        this.publicGroupSavings = res
        setTimeout(() => {
          this.publicgroupIsLoading = false
        }, 1000)
      } catch (err) {
        
      }
    },
    async getAgentSavings() {
      const data = {
        user_id: this.user.id,
      }
      try {
        let response = await this.$store.dispatch('savings/allagentsavings', data)
        this.agentSavings = response.groups
        this.a = Object.values(this.privategroupSavings)
        this.a = this.a.map(({balance}) => ({balance}))
        this.a = this.a.map(({ balance }) => balance)
        this.a = this.a.reduce((a,b) => a + b, 0)
        setTimeout(() => {
          this.agentIsLoading = false
        }, 1000)
      } catch (error) {
        
      } 
    },
    async joinGroup(group_id) {
      const data = {
        user_id: this.user.id,
        group_id: group_id
      }
      try{
        let res = await this.$store.dispatch('savings/joinpublicgroup', data)
        this.$toast.success(res.message)
      } catch(err) {
        this.$toast.error(err.response.data.message)
      }
    },
    async joinAgent(agent_group_id) {
      const data = {
        user_id: this.user.id,
        group_id: agent_group_id
      }
      try{
        let res = await this.$store.dispatch('savings/joinagentgroup', data)
        this.$toast.success(res.message)
      } catch(err) {
        this.$toast.error(err.response.data.message)
      }
    }
  },

  mounted() {
    this.getWalletBalance()
    this.getAgentSavings()
    this.getPublicGroupSavings()
    this.getPersonalSavings()
    this.getPrivateGroupSavings()
  },
}
</script>
<style lang="scss" scoped>
.next-card {
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
  }
}
.single-savings-card {
  border: 0.5px solid #d9dbfa;
  box-shadow: 0px 2px 2px rgba(171, 153, 153, 0.25) !important;
  box-sizing: border-box;
  border-radius: 5px;
  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 132.5%;
    color: #c1a13d;
  }
  &:hover {
    background: #ffffff;
    border: 0.5px solid #484dc7;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px 9px rgba(219, 200, 200, 0.25);
    border-radius: 5px;
  }
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 105.5%;
    color: #2f2d2d;
    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 132.5%;
      color: #171b70;
    }
  }
  .v-card {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 132.5%;
    color: #171b70;
  }
}
.v-progress-linear {
  border-radius: 10px !important;
}
.vote_ {
  border: 1px solid #DDDDDD;
}
.days-left {
  height: 48px;
  width: 48px;
  border-radius: 40px;
  background: #c4c4c4;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 132.5%;
  /* or 21px */

  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;
}
.v-tabs-slider {
  background-color: #c1a13d
}
.actin_cursr {
  cursor: pointer;
}
.outl {
  background-color: #EEEEEE;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.avatars {
  transform: scale(-1, 1);
  display: inline-block;
}
.avatar {
  position: relative;
  display: inline-block;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}

.avatar:not(:first-child) {
  margin-left: -10px;
}
.avatar img {
  display: block;
}
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0px !important
}
.loading {
  height: calc(100vh);
  position: absolute;
  display: block;
  width: 100%;
  top: 40%;
}
a {
  text-decoration: none !important;
}
</style>
